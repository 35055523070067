<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="add-lesson"
      ref="add-lesson"
      centered
      title="Add member to group"
      hide-footer
    >
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <div class="col-12">
              <b-form
                ref="form"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in list_member"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col md="8">
                    <b-form-group
                      label="ID Member"
                      label-for="item-name"
                    >
                      <b-form-input
                        id="item-name"
                        v-model="item.value"
                        type="text"
                        placeholder="ID Member"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <!--                      <span>Delete</span>-->
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>

              </b-form>
            </div>
            <b-col md="12">
              <b-button
                variant="primary"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <!--                <span>Add New</span>-->
              </b-button>
            </b-col>

            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addNewRecord"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="resetForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

    <!-- input search -->
    <b-row class="mb-2">

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-md-0"
      >
        <div class="custom-search d-flex justify-content-center">
          <b-form-group>
            <div class="mb-1">
              <b-button
                v-b-modal.add-lesson
                variant="secondary"
                @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  class="mr-50"
                />
                <span>{{ $t('Back') }}</span>
              </b-button>
            </div>
<!--            <div class="">-->
<!--              <b-button-->
<!--                v-b-modal.add-lesson-->
<!--                variant="primary"-->
<!--                @click="resetForm"-->
<!--              >-->
<!--                <feather-icon-->
<!--                  icon="PlusIcon"-->
<!--                  class="mr-50"-->
<!--                />-->
<!--                {{ $t('Add Member') }}-->
<!--              </b-button>-->

<!--            </div>-->

          </b-form-group>

        </div>

      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      style-class="vgt-table bordered striped"
      :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'displayName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.displayName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant('Active')">
            {{ `Active` }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
<!--              <b-dropdown-item-->
<!--                v-b-modal.add-lesson-->
<!--                @click="openFormUpdate(props)"-->
<!--              >-->
<!--                <feather-icon-->
<!--                  icon="Edit2Icon"-->
<!--                  class="mr-50"-->
<!--                />-->
<!--                <span>Edit</span>-->
<!--              </b-dropdown-item>-->
              <b-dropdown-item @click="deleteRecord(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Remove from group</span>
              </b-dropdown-item>
<!--              <b-dropdown-item @click="resetPassword(props.row)">-->
<!--                <feather-icon-->
<!--                  icon="RotateCcwIcon"-->
<!--                  class="mr-50"-->
<!--                />-->
<!--                <span>Reset Password</span>-->
<!--              </b-dropdown-item>-->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
    name: 'List',
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    data() {
        return {
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: 'Name',
                    field: 'displayName',
                },
                {
                    label: 'Username',
                    field: 'userPrincipalName',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [],
            newRecord: {
                accountEnabled: true,
                displayName: '',
                mobilePhone: '',
                userPrincipalName: '',
                passwordProfile: {
                    password: '',
                },
                mailNickname: '',
                mail: '',
            },
            update_record: {
                isUpdate: false,
                id: '',
            },
            list_member: [{
                id: 1,
                value: '',
            }],
            nextTodoId: 2,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current      : 'light-primary',
                Active : 'light-success',
                Rejected     : 'light-danger',
                Resigned     : 'light-warning',
                Applied      : 'light-info',
                /* eslint-enable key-spacing */
            }

            return (status) => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    async created() {
        this.$swal_show()
        await this.getListUser()
        this.$swal_hide()
    },
    methods: {
        repeateAgain() {
            this.list_member.push({
                id: this.nextTodoId += this.nextTodoId,
            })
        },
        removeItem(index) {
            this.items.splice(index, 1)
        },
        async getListUser() {
            this.rows = []
            await this.$http.post('/third-party/list-member-group', { group_id: this.$route.query.group_id })
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.rows = data.value
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        resetForm() {
            this.newRecord = {
                accountEnabled: true,
                displayName: '',
                mobilePhone: '',
                userPrincipalName: '',
                passwordProfile: {
                    password: '',
                },
                mailNickname: '',
                mail: '',
            }
        },
        hideModal(modalID) {
            this.$refs[modalID].hide()
        },
        confirmText() {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        openFormUpdate(props) {
            this.update_record.isUpdate = true
            this.update_record.id = props.row.id
            this.newRecord = {
                displayName: props.row.displayName,
                mobilePhone: props.row.mobilePhone,
                userPrincipalName: props.row.userPrincipalName,
                // mailNickname: props.row.mailNickname,
                mail: props.row.mail,
            }
        },
        async addNewRecord() {
            if (this.update_record.isUpdate) {
                await this.updateRecord(this.update_record.id, this.newRecord)
            } else {
                const listMember = {
                    'members@odata.bind': [],
                }
                this.list_member.forEach((item) => {
                    const id = `https://graph.microsoft.com/v1.0/directoryObjects/${item.value}`
                    listMember['members@odata.bind'].push(id)
                })
                await this.$http.post('/third-party/add-member-group', { group_id: this.$route.query.group_id, list_member: listMember })
                    .then(async (res) => {
                        const { data, message, success } = res.data
                        if (success) {
                            await this.getListUser()
                            this.hideModal('add-lesson')
                            this.$swal({
                                title: 'Good job!',
                                text: 'Add new lesson success!',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__tada',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            this.$error(message)
                        }
                    }).catch((error) => {
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            }
        },
        async deleteRecord(record) {
            await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Remove it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    this.$swal_show()
                    await this.$http.post('/third-party/remove-member-group', { group_id: this.$route.query.group_id, user_id: record.id })
                        .then((res) => {
                            const { data, message, success } = res.data
                            if (success) {
                                this.getListUser()
                                this.$success('Remove member success')
                            } else {
                                this.$error(message)
                            }
                        }).catch((error) => {
                            const status = _.get(error, 'response.status', 'unknown')
                            const reason = _.get(error, 'response.statusText', 'unknown')
                            const message = _.get(error, 'response.data.message', 'unknown')

                            if (_.isArray(message)) {
                                message.forEach((msg) => {
                                    this.$error(msg)
                                })
                            } else {
                                this.$error(message)
                            }
                        })
                    this.$swal_hide()
                    this.$swal({
                        icon: 'success',
                        title: 'Remove!',
                        text: 'Your member has been removed!.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        async resetPassword(record) {
            await this.$http.post('/third-party/reset-password-user', { user_id: record.id, username: record.userPrincipalName })
                .then((res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.$success('Reset Password success')
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async updateRecord(id, update) {
            await this.$http.post('/third-party/update-user', { user_id: id, updateUser: update })
                .then((res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.update_record.isUpdate = false
                        this.update_record.id = ''
                        this.getListUser()
                        this.hideModal('add-lesson')
                        this.$success('Update success')
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.back-btn {
  margin-bottom: 14px;
}
</style>
